interface InitOptions {
  shakaVersion?: string;
  customNamespaces?: Record<string, framework.system.MessageType>;
  onShutdown?: () => void;
}

export const { MessageType } = cast.framework.system;

export function initCastReceiver(options?: InitOptions) {
  const context = cast.framework.CastReceiverContext.getInstance();

  const castOptions = new cast.framework.CastReceiverOptions();
  castOptions.maxInactivity = import.meta.env.DEV ? 3600 : undefined;
  castOptions.shakaVersion = options?.shakaVersion;
  castOptions.shakaVariant =
    import.meta.env.VITE_SHAKA_DEBUG === 'true' ?
      cast.framework.ShakaVariant.DEBUG
    : cast.framework.ShakaVariant.STANDARD;
  castOptions.useShakaForHls = true;
  castOptions.skipMplLoad = true;
  castOptions.playbackConfig = new cast.framework.PlaybackConfig();
  castOptions.customNamespaces = options?.customNamespaces;

  context.start(castOptions);

  if (options?.onShutdown) {
    context.addEventListener(
      cast.framework.system.EventType.SHUTDOWN,
      options?.onShutdown,
    );
  }
}
