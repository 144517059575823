import { ErrorCode, Location, VrioOttError } from '@dtvgo/error';

import { LANGUAGE } from './constants';

const NOT_IN_PAKAGE_TITLE_PT =
  'Ops! Para assistir a este conteúdo, você precisa adicionar Extras';

const MESSAGES_PT = {
  READY_TO_CAST_TITLE: 'Pronto! Aparelho conectado',
  SELECT_CONTENT_ON_DEVICE:
    'Você já pode escolher um conteúdo no seu dispositivo e curtir pela TV.',
  SELECT_CONTENT_TO_STREAM: 'Escolha um conteúdo para transmitir',
  CONNECTING_YOUR_DEVICE: 'Conectando seu dispositivo ao Chromecast...',
  ERROR_SCREEN: {
    CODE: 'Código',
    DEVICE: 'aparelho',
    DEVICES: 'aparelhos',
    ERRORS: {
      [ErrorCode.BLACKOUT]: {
        [Location.PLAYER_PLAYBACK]: {
          title: 'Poxa! O conteúdo que acabou de começar não está disponível',
          message:
            'Por causa dos direitos de transmissão, não dá pra assistir ao conteúdo na SKY+.',
        },
        title: 'Poxa! Este conteúdo não está disponível agora',
        message:
          'Por causa dos direitos de transmissão, não dá pra assistir ao conteúdo na SKY+.',
      },
      [ErrorCode.PARENTAL_RESTRICTION]: {
        title: 'Ops! Este conteúdo está bloqueado pelo controle dos pais',
        message:
          'Para assistir ao conteúdo, você precisa trocar para um perfil que não tenha restrições.',
      },
      [ErrorCode.LOST_INTERNET_CONNECTION]: {
        title: 'Ops! Você precisa verificar sua internete',
        message:
          'Confira se o Wi-Fi ou o cabo de rede estão conectados para continuar.',
      },
      [ErrorCode.NOT_IN_PACKAGE_OTT]: {
        title: NOT_IN_PAKAGE_TITLE_PT,
        message: 'É só acessar a sua conta da SKY+ pelo computador.',
      },
      [ErrorCode.NOT_IN_PACKAGE_DTH]: {
        title: NOT_IN_PAKAGE_TITLE_PT,
        message:
          'É só acessar a sua conta da SKY pelo computador e escolher os Extras que você quer.',
      },
      [ErrorCode.NOT_IN_PACKAGE_B2B2C]: {
        title: NOT_IN_PAKAGE_TITLE_PT,
        message:
          'É só entrar em contato com a sua operadora e escolher os Extras que você quer.',
      },
      [ErrorCode.NOT_IN_PACKAGE_GOOGLE_IAP_OTT]: {
        title:
          'Ops! Este conteúdo não está disponível no seu plano do Google Play',
        message:
          'Acesse a conta por um aparelho móvel com sistema operacional Android.',
      },
      [ErrorCode.NOT_IN_REGION]: {
        title: 'Poxa! Você não pode assistir à SKY+ onde está agora',
        message:
          'O conteúdo só será mostrado quando você voltar para o país em que criou a sua conta.',
      },
      [ErrorCode.REGION_BLOCK]: {
        title: 'Poxa! Este conteúdo não está disponível na sua região',
        message:
          'Você pode escolher outro canal ao vivo pra assistir na nossa programação.',
      },
      [ErrorCode.AUTHORIZER_CONCURRENT_USER_LIMIT]: {
        title:
          'Ops! Já tem {deviceCount} {pluralizedDevices} reproduzindo conteúdos nesta conta',
        message:
          'Para poder usar aqui, um dos aparelhos conectados precisa parar de reproduzir conteúdos.',
      },
      [ErrorCode.DEVICE_LIMIT_REACHED]: {
        title: 'Ops! Tivemos que desconectar este aparelho',
        message:
          'Só dá pra conectar até 5 aparelhos na mesma conta. Para usar aqui, você precisa entrar na conta de novo.',
      },
      [ErrorCode.AUTHORIZER_NOT_VALID_LOGIN_TOKEN]: {
        title: 'Ops! Algo deu errado',
        message:
          'Estamos verificando o que aconteceu pra você continuar assistindo ao conteúdo. Se não conseguir, saia da sua conta e entre de novo.',
      },
      [ErrorCode.AUTHORIZER_CONCURRENT_CHANNEL_LIMIT]: {
        title: 'Ops! Este canal está sendo reproduzido em outro aparelho',
        message:
          'Você pode escolher outro canal ao vivo pra curtir na nossa programação.',
      },
      [ErrorCode.AUTHORIZER_CONCURRENT_DEVICE_LIMIT]: {
        title: 'Você já está assistindo a conteúdos neste aparelho',
        message: 'Só dá pra reproduzir 1 conteúdo por vez aqui.',
      },
      [ErrorCode.REFRESH_TOKEN_EXPIRED]: {
        title: 'Algo deu errado pra reproduzir este conteúdo',
        message: 'Você pode tentar de novo.',
      },
      GENERIC: {
        title: 'Ops! Algo deu errado',
        message:
          'Estamos verificando o que aconteceu pra você continuar assistindo ao conteúdo.',
      },
    },
  },
  LIVE: 'Ao vivo',
  MOVIE: 'Filme',
  SERIE: 'Série',
  LOADING_CONTENT: 'Carregando conteúdo...',
  PAUSE_HINT:
    'Para mudar as configurações deste conteúdo, use o aparelho conectado.',
  SEASON_FIRST_CHARACTER: 'T',
  EPISODE_FIRST_CHARACTER: 'E',
};

const MESSAGES_ES = {
  READY_TO_CAST_TITLE: '¡Todo listo! Dispositivo conectado',
  SELECT_CONTENT_ON_DEVICE:
    'Elige un contenido en tu dispositivo para disfrutarlo en la televisión.',
  SELECT_CONTENT_TO_STREAM: 'Elige un contenido para transmitir',
  CONNECTING_YOUR_DEVICE: 'Conectando tu dispositivo a Chromecast...',
  ERROR_SCREEN: {
    CODE: 'Código',
    DEVICE: 'dispositivo',
    DEVICES: 'dispositivos',
    ERRORS: {
      [ErrorCode.BLACKOUT]: {
        [Location.PLAYER_PLAYBACK]: {
          title: '¡Uy! El contenido que acaba de empezar no está disponible',
          message:
            'Debido a derechos de transmisión, este contenido no se puede ver en DGO por el momento.',
        },
        title: '¡Ups! Este contenido no está disponible por el momento',
        message:
          'Por derechos de transmisión, este contenido no se puede ver en DGO.',
      },
      [ErrorCode.PARENTAL_RESTRICTION]: {
        title: '¡Ups! Este contenido está bloqueado por el control parental',
        message:
          'Para ver el contenido, debes cambiar a un perfil que no tenga restricciones.',
      },
      [ErrorCode.LOST_INTERNET_CONNECTION]: {
        title: '¡Revisa tu internet!',
        message:
          'Asegúrate que el WiFi o el cable de red esten conectados para continuar.',
      },
      [ErrorCode.NOT_IN_PACKAGE_OTT]: {
        title: 'Para ver este contenido debes añadir un paquete extra',
        message: 'Solo accede a tu cuenta de DGO desde tu computador.',
      },
      [ErrorCode.NOT_IN_PACKAGE_DTH]: {
        title:
          'Para ver este contenido debes añadir un paquete premium a tu plan',
        message:
          'Ve a tu cuenta de MiDIRECTV desde tu computador y elige el contenido extra que quieras agregar.',
      },
      [ErrorCode.NOT_IN_PACKAGE_B2B2C]: {
        title: 'Para ver este contenido debes añadir un Extra',
        message:
          'Ve a la cuenta de tu proveedor y añade el contenido extra que quieras ver en DGO',
      },
      [ErrorCode.NOT_IN_PACKAGE_GOOGLE_IAP_OTT]: {
        title: 'Este contenido no está disponible en tu plan de Google Play',
        message:
          'Accede a tu cuenta desde un dispositivo móvil con sistema operativo Android.',
      },
      [ErrorCode.NOT_IN_REGION]: {
        title: 'Este canal no está disponible donde estás ahora',
        message:
          'El contenido se te mostrará cuando regreses al país donde creaste la cuenta.',
      },
      [ErrorCode.REGION_BLOCK]: {
        title: 'Este canal no está disponible en tu región',
        message:
          'Puedes elegir otro canal en vivo para disfrutar de nuestra programación.',
      },
      [ErrorCode.AUTHORIZER_CONCURRENT_USER_LIMIT]: {
        title:
          '¡Ups! Ya hay {deviceCount} {pluralizedDevices} reproduciendo contenido en esta cuenta',
        message:
          'Para ver aquí, debes detener la reproducción de contenido en uno de tus otros dispositivos.',
      },
      [ErrorCode.DEVICE_LIMIT_REACHED]: {
        title: 'Tuvimos que cerrar tu sesión en este dispositivo',
        message:
          'Solo puedes tener tu cuenta abierta en 5 dispositivos a la vez. Si quieres conectarte aquí, vuelve a iniciar sesión.',
      },
      [ErrorCode.AUTHORIZER_NOT_VALID_LOGIN_TOKEN]: {
        title: '¡Ups! Algo pasó',
        message:
          'Estamos revisando lo que sucedió para que puedas seguir viendo el contenido. Si el error persiste, cierra sesión e iniciar de nuevo.',
      },
      [ErrorCode.AUTHORIZER_CONCURRENT_CHANNEL_LIMIT]: {
        title: 'Este canal ya está siendo reproducido en otro dispositivo',
        message:
          'Elige otro canal en vivo para disfrutar de nuestra programación.',
      },
      [ErrorCode.AUTHORIZER_CONCURRENT_DEVICE_LIMIT]: {
        title: 'Ya estás viendo contenido en este dispositivo',
        message: 'Solo puedes ver un contenido a la vez por dispositivo.',
      },
      [ErrorCode.REFRESH_TOKEN_EXPIRED]: {
        title: '¡Lo sentimos! Algo falló al reproducir este contenido',
        message: 'Puedes intentarlo de nuevo.',
      },
      GENERIC: {
        title: '¡Ups! Algo pasó',
        message:
          'Estamos revisando lo que sucedió para que puedas seguir viendo el contenido.',
      },
    },
  },
  LIVE: 'En Vivo',
  MOVIE: 'Pelicula',
  SERIE: 'Serie',
  LOADING_CONTENT: 'Cargando contenido...',
  PAUSE_HINT:
    'Usa tu dispositivo conectado para gestionar o cambiar la configuración en tu televisor.',
  SEASON_FIRST_CHARACTER: 'T',
  EPISODE_FIRST_CHARACTER: 'E',
};

const ALL_MESSAGES = {
  pt: MESSAGES_PT,
  es: MESSAGES_ES,
};

export const MESSAGES = ALL_MESSAGES[LANGUAGE];

export function getErrorTexts(error: VrioOttError) {
  let messagesToReturn =
    MESSAGES.ERROR_SCREEN.ERRORS[
      error.code as keyof typeof MESSAGES.ERROR_SCREEN.ERRORS
    ] || MESSAGES.ERROR_SCREEN.ERRORS.GENERIC;

  const textsByLocation = messagesToReturn[
    error.location as Exclude<
      keyof typeof messagesToReturn,
      'title' | 'message'
    >
  ] as { title: string; message: string } | undefined;

  if (textsByLocation) {
    messagesToReturn = textsByLocation;
  }

  const deviceCount = (
    error.data?.response?.data as { csm: number } | undefined
  )?.csm;
  const pluralizedDevices =
    deviceCount === 1 ?
      MESSAGES.ERROR_SCREEN.DEVICE
    : MESSAGES.ERROR_SCREEN.DEVICES;

  return {
    title: formatMessage(messagesToReturn.title, {
      deviceCount: `${deviceCount}`,
      pluralizedDevices,
    }),
    message: messagesToReturn.message,
  };
}

export function formatMessage(
  message: string,
  values: Record<string, string | undefined>,
) {
  const [...matches] = message.matchAll(/{([A-Z_a-z]\w*)}/g);
  let formattedMessage = message;

  for (const match of matches) {
    formattedMessage = formattedMessage.replace(
      match[0],
      values[match[1]] || '',
    );
  }

  return formattedMessage;
}
