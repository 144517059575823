import { ErrorCode, VrioOttError } from '@dtvgo/error';
import {
  PlayerError,
  PlayerErrorSeverity,
  PlayerRequestType,
} from '@dtvgo/player-common-utils';

export interface AppInfo {
  name: string;
  version: string;
}

export interface DeviceInfo {
  model?: string;
  osVersion?: string;
  firmwareVersion?: string;
}

export interface ConfigureAdaptersOptions {
  errorFormatter: (error: unknown) => {
    name: string;
    description: string;
    metadata: object;
    isFatal: boolean;
  };
}

interface GetErrorCategoryOptions {
  isConnected?: () => boolean;
}

export enum NpawErrorCategory {
  AUTHORIZATION_NOT_IN_PACKAGE = 'Bus: Authorization NIP',
  AUTHORIZATION_CONCURRENCY = 'Bus: Authorization CSM',
  AUTHORIZATION_REGION = 'Bus: Authorization Region',
  AUTHENTICATION_LOGOUT = 'Bus: Authentication Logout',
  BLACKOUT = 'Bus: Blackout',
  PARENTAL = 'Bus: Parental',
  PLAYER_INIT = 'Tech: Player init',
  PLAYER_RENDER = 'Tech: Player Render',
  MANIFEST = 'Tech: Manifest',
  CDN = 'Tech: CDN',
  DRM = 'Tech: DRM',
  AUTHORIZATION_OTHER = 'Tech: Authorization',
  AUTHENTICATION_OTHER = 'Tech: Authentication',
  DEVICE_NETWORK = 'Tech: Device Network',
  DEVICE_OTHER = 'Tech: Device Other',
  UNEXPECTED = 'Tech: Unexpected',
}

const ErrorCategoryByRequestType = {
  [PlayerRequestType.MANIFEST]: NpawErrorCategory.CDN,
  [PlayerRequestType.SEGMENT]: NpawErrorCategory.CDN,
  [PlayerRequestType.LICENSE]: NpawErrorCategory.DRM,
  [PlayerRequestType.SERVER_CERTIFICATE]: NpawErrorCategory.DRM,
  [PlayerRequestType.OTHER]: NpawErrorCategory.UNEXPECTED,
  [PlayerRequestType.APP]: NpawErrorCategory.UNEXPECTED,
  [PlayerRequestType.ADS]: NpawErrorCategory.UNEXPECTED,
  [PlayerRequestType.KEY]: NpawErrorCategory.UNEXPECTED,
  [PlayerRequestType.TIMING]: NpawErrorCategory.UNEXPECTED,
};

const ErrorCategoryByPlayerErrorCategory = {
  [PlayerError.Category.NETWORK]: NpawErrorCategory.UNEXPECTED,
  [PlayerError.Category.STREAMING]: NpawErrorCategory.PLAYER_RENDER,
  [PlayerError.Category.MANIFEST]: NpawErrorCategory.MANIFEST,
  [PlayerError.Category.DRM]: NpawErrorCategory.DRM,
  [PlayerError.Category.OTHER]: NpawErrorCategory.UNEXPECTED,
};

const ErrorCategoryByPlayerErrorCode = {
  [PlayerError.Code.STREAMING_ERROR]: NpawErrorCategory.DEVICE_OTHER,
  [PlayerError.Code.BAD_PLAYER_INITIALIZATION]: NpawErrorCategory.PLAYER_INIT,
};

const ErrorCategoryByErrorCode = {
  [ErrorCode.BLACKOUT]: NpawErrorCategory.BLACKOUT,
  [ErrorCode.MOBILE_BLACKOUT]: NpawErrorCategory.BLACKOUT,
  [ErrorCode.LOST_WIFI_CONNECTION]: NpawErrorCategory.DEVICE_NETWORK,
  [ErrorCode.LOST_INTERNET_CONNECTION]: NpawErrorCategory.DEVICE_NETWORK,
  [ErrorCode.NOT_IN_REGION]: NpawErrorCategory.AUTHORIZATION_REGION,
  [ErrorCode.REGION_BLOCK]: NpawErrorCategory.AUTHORIZATION_REGION,
  [ErrorCode.AUTHORIZER_CONCURRENT_USER_LIMIT]:
    NpawErrorCategory.AUTHORIZATION_CONCURRENCY,
  [ErrorCode.AUTHORIZER_CONCURRENT_CHANNEL_LIMIT]:
    NpawErrorCategory.AUTHORIZATION_CONCURRENCY,
  [ErrorCode.AUTHORIZER_CONCURRENT_DEVICE_LIMIT]:
    NpawErrorCategory.AUTHORIZATION_CONCURRENCY,
  [ErrorCode.AUTHORIZER_NOT_VALID_LOGIN_TOKEN]:
    NpawErrorCategory.AUTHORIZATION_OTHER,
  [ErrorCode.AUTHORIZER_NOT_VALID_CUSTOMER_TOKEN]:
    NpawErrorCategory.AUTHORIZATION_OTHER,
  [ErrorCode.AUTHORIZER_NOT_VALID_ASSET_TOKEN]:
    NpawErrorCategory.AUTHORIZATION_OTHER,
  [ErrorCode.AUTHORIZER_NOT_VALID_REFRESH_TOKEN]:
    NpawErrorCategory.AUTHORIZATION_OTHER,
  [ErrorCode.AUTHORIZER_OTHERS]: NpawErrorCategory.AUTHORIZATION_OTHER,
  [ErrorCode.SESSION_EXPIRED]: NpawErrorCategory.AUTHENTICATION_LOGOUT,
  [ErrorCode.LOGIN_REFRESH_TOKEN_FAILED]:
    NpawErrorCategory.AUTHENTICATION_OTHER,
  [ErrorCode.DRM_FAILED]: NpawErrorCategory.DRM,
  [ErrorCode.NOT_IN_PACKAGE_OTT]:
    NpawErrorCategory.AUTHORIZATION_NOT_IN_PACKAGE,
  [ErrorCode.NOT_IN_PACKAGE_DTH]:
    NpawErrorCategory.AUTHORIZATION_NOT_IN_PACKAGE,
  [ErrorCode.NOT_IN_PACKAGE_B2B2C]:
    NpawErrorCategory.AUTHORIZATION_NOT_IN_PACKAGE,
  [ErrorCode.DEVICE_LIMIT_REACHED]: NpawErrorCategory.AUTHENTICATION_LOGOUT,
  [ErrorCode.USER_INACTIVE]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.NOT_ENOUGH_DATA_TO_PLAY_CONTENT]: NpawErrorCategory.PLAYER_RENDER,
  [ErrorCode.REQUEST_TIMEOUT]: (
    error: VrioOttError,
    options: GetErrorCategoryOptions,
  ) => {
    if (options.isConnected?.() === false) {
      return NpawErrorCategory.DEVICE_NETWORK;
    }

    return getErrorCategoryByPlayerRequestType(error);
  },
  [ErrorCode.LOGOUT]: NpawErrorCategory.AUTHENTICATION_LOGOUT,
  [ErrorCode.INVALID_PLAYLOAD_RESPONSE]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.TOKEN_WITHOUT_CHANNELS]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.MISSING_DATA]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.LIMIT_STALLED]: NpawErrorCategory.PLAYER_RENDER,
  [ErrorCode.PARENTAL_RESTRICTION]: NpawErrorCategory.PARENTAL,
  [ErrorCode.NOT_IN_PACKAGE_GOOGLE_IAP_OTT]:
    NpawErrorCategory.AUTHORIZATION_NOT_IN_PACKAGE,
  [ErrorCode.ACCOUNT_WITHOUT_PACKAGE]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.SSL_HANDSHAKE_ERROR]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.AUTHORIZER_CONCURRENT_PROVIDER_LIMIT]:
    NpawErrorCategory.AUTHORIZATION_CONCURRENCY,
  [ErrorCode.CAST_AUTO_LOGIN_FAILED]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.CAST_ERROR_CHROMECAST]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.CAST_ERROR_AIRPLAY]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.BET_NOT_ALLOWED]: NpawErrorCategory.PARENTAL,
  [ErrorCode.INTERACTIVITY_MODULE_INCOMPATIBLE]: NpawErrorCategory.DEVICE_OTHER,
  [ErrorCode.REFRESH_TOKEN_EXPIRED]: NpawErrorCategory.AUTHENTICATION_LOGOUT,
  [ErrorCode.RECAPTCHA_SERVER_ERROR]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.IMPOSSIBLE_TO_CREATE_REQUEST]: NpawErrorCategory.UNEXPECTED,
  [ErrorCode.PLAYER_EXCEPTION]: (error: VrioOttError) => {
    if (
      error.data?.playerErrorCode &&
      ErrorCategoryByPlayerErrorCode[
        error.data
          .playerErrorCode as keyof typeof ErrorCategoryByPlayerErrorCode
      ]
    ) {
      return ErrorCategoryByPlayerErrorCode[
        error.data
          .playerErrorCode as keyof typeof ErrorCategoryByPlayerErrorCode
      ];
    }
    if (
      error.data?.playerErrorCategory &&
      ErrorCategoryByPlayerErrorCategory[error.data.playerErrorCategory]
    ) {
      return ErrorCategoryByPlayerErrorCategory[error.data.playerErrorCategory];
    }
    return NpawErrorCategory.UNEXPECTED;
  },
  [ErrorCode.OTHER_HTTP_ERROR]: getErrorCategoryByPlayerRequestType,
  [ErrorCode.UNEXPECTED_ERROR]: NpawErrorCategory.UNEXPECTED,
};

export function getValueOrEmpty<T>(value: T | null | undefined) {
  return value ?? '';
}

export function getValueOrNotApplicable<T>(value: T | null | undefined) {
  return value ?? 'Not applicable';
}

export function getValueOrNotAvailable<T>(value: T | null | undefined) {
  return value ?? 'Not available';
}

export function prependIfValue(
  prepend: string,
  value: string | number | null | undefined,
) {
  return value ? `${prepend}${value}` : '';
}

function getErrorCategoryByPlayerRequestType(error: VrioOttError) {
  if (error.data?.playerErrorData?.requestType) {
    return ErrorCategoryByRequestType[error.data.playerErrorData.requestType];
  }

  return NpawErrorCategory.UNEXPECTED;
}

export function getErrorCategory(
  error: VrioOttError,
  options: GetErrorCategoryOptions,
) {
  const errorCategory =
    ErrorCategoryByErrorCode[
      error.code as keyof typeof ErrorCategoryByErrorCode
    ];

  if (typeof errorCategory === 'function') {
    return errorCategory(error, options);
  }

  return errorCategory;
}

export function getErrorDescription(error: VrioOttError) {
  if (error.data?.response?.status) {
    return [
      `statusCode: ${error.data.response.status}`,
      error.data.response.message,
    ]
      .filter(Boolean)
      .join(' - ');
  }

  return [
    [ErrorCode.BLACKOUT, ErrorCode.PARENTAL_RESTRICTION].includes(error.code) ?
      'message'
    : 'stack',
    error.message,
  ].join(': ');
}

export function getErrorParams(
  error: VrioOttError,
  options: GetErrorCategoryOptions,
) {
  const category = getErrorCategory(error, options);

  return {
    name: category,
    description: `[${error.platformName}][${category}][${error.userCode}] - ${getErrorDescription(error)}`,
    metadata: {
      userErrorCode: error.userCode,
      internalCode: error.data?.internalCode,
      requestURL: error.data?.request?.url,
      responseStatusCode: error.data?.response?.status,
      responsePayload: error.data?.response?.data,
      detail: error.toJSON(),
    },
    isFatal: !(
      error.data?.playerErrorSeverity &&
      [PlayerErrorSeverity.HANDLED, PlayerErrorSeverity.NON_CRITICAL].includes(
        error.data.playerErrorSeverity,
      )
    ),
  };
}
